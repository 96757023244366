import React, { useState, useEffect } from "react";
import ConfirmPassword from "./confirmPassword";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { analytics, firebase_app, Jwt_token } from "../data/config";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
} from "../constant";
import ForgotPasswordModal from "./forgotPasswordModal";

const Logins = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    let re = /\S+@\S+\.\S+/;
    try {
      await firebase_app
        .auth()
        .signInWithEmailAndPassword(
          !re.test(email) ? email + "@muskurahat.org.in" : email,
          password
        )
        .then(function (user) {
          analytics.logEvent("login");
          firebase_app
            .firestore()
            .collection("donors")
            .doc(user.user.uid)
            .get()
            .then((donor) => {
              if (donor.data().isNewUser) {
                setShowResetModal(true);
                return;
              } else {
                setTimeout(() => {
                  props.history.push(
                    `${process.env.PUBLIC_URL}/dashboard/news-feed`
                  );
                }, 200);
              }
            });

          setValue(man);
          setName(user.user.displayName);
          localStorage.setItem("token", Jwt_token);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error("Oppss.. " + error.message);
        setLoading(false);
      }, 200);
    }
  };

  const [showResetModal, setShowResetModal] = useState(false);

  const toggleForgotPassword = () =>
    setForgotPasswordModal(!forgotPasswordModal);

  return (
    <Container fluid={true} className="p-0">
      <Row style={{ width: "100%" }}>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div style={{ maxwidth: "200px" }}>
                <a className="logo" href="index.html">
                  <img
                    className="for-light"
                    src={require("../assets/images/logo/logo.png")}
                    alt=""
                    style={{ maxWidth: "200px" }}
                  />
                  <img
                    className="for-dark"
                    src={require("../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <h4>Sign In</h4>
                  <p>{"Enter your email & password to login"}</p>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      required=""
                      onChange={(e) => setEmail(e.target.value)}
                      defaultValue={email}
                    />
                  </FormGroup>
                  <div className="form-group" style={{ marginBottom: 25 }}>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      defaultValue={password}
                      required=""
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3" style={{ marginBottom: 25 }}>
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <span
                      className="link forgotPassword"
                      onClick={toggleForgotPassword}
                      style={{ cursor: "pointer" }}
                    >
                      {ForgotPassword}
                    </span>
                    <Button
                      color="primary"
                      className="btn-block"
                      disabled={loading ? loading : loading}
                      onClick={(e) => loginAuth(e)}
                    >
                      {loading ? "LOADING..." : SignIn}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {showResetModal && (
        <ConfirmPassword toggle={() => setShowResetModal(false)} />
      )}
      {forgotPasswordModal && (
        <ForgotPasswordModal toggle={() => setForgotPasswordModal(false)} />
      )}
    </Container>
  );
};

export default withRouter(Logins);
