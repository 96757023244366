import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Video, Activity, Image } from "react-feather";
import InfiniteScroll from "react-infinite-scroll-component";
import { firebase_app } from "../../../data/config";
import SelectedContext from "../../../context/selected-context";
import Loader from "../../ui-kits/loader";
import moment from "moment";

const size = 3;
const VerticalTimelineComp = () => {
  const [items, setItems] = useState([]);
  const [last, setLast] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const { selectedShelterhome } = useContext(SelectedContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    firebase_app
      .firestore()
      .collection(`shelterHome/${selectedShelterhome}/posts`)
      .orderBy("createdAt", "desc")
      .limit(size)
      .get()
      .then((snap) => {
        const list = snap.docs.map((d, i) => (
          <VerticalTimelineElement
            key={i}
            className="vertical-timeline-element--work"
            animate={true}
            date={moment(d.data().date).format("Do MMM YYYY")}
            icon={
              d.data().type === "photo" ? (
                <Image />
              ) : d.data().type === "none" ? (
                <Activity />
              ) : (
                <Video />
              )
            }
          >
            <h5 className="vertical-timeline-element-subtitle">
              {d.data().heading}
            </h5>
            {d.data().type === "photo" ? (
              d.data().url === "" ? null : (
                <div>
                  <img
                    className="img-fluid p-t-20"
                    src={d.data().url}
                    alt={d.data().heading}
                  />
                </div>
              )
            ) : (
              <div className="embed-responsive embed-responsive-21by9 m-t-20">
                <iframe
                  src={d.data().url}
                  allowFullScreen
                  title="myFrame"
                ></iframe>
              </div>
            )}
          </VerticalTimelineElement>
        ));
        setIsLoading(false);
        setItems([list]);
        if (snap.docs.length < size) {
          setHasMore(false);
        }
        setLast(snap.docs[snap.docs.length - 1]);
      });
  }, [selectedShelterhome]);

  const paginate = () => {
    console.log("HELLO I AM HERE");
    firebase_app
      .firestore()
      .collection(`shelterHome/${selectedShelterhome}/posts`)
      .orderBy("createdAt", "desc")
      .startAfter(last)
      .limit(size)
      .get()
      .then((snap) => {
        const list = snap.docs.map((d) => {
          console.log(d.id, d.data());
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              animate={true}
              date={d.data().date}
              icon={
                d.data().type === "photo" ? (
                  <Image />
                ) : d.data().type === "none" ? (
                  <Activity />
                ) : (
                  <Video />
                )
              }
            >
              <h4 className="vertical-timeline-element-subtitle">
                {d.data().heading}
              </h4>
              {d.data().type === "photo" ? (
                <img
                  className="img-fluid p-t-20"
                  src={d.data().url}
                  alt={d.data().heading}
                />
              ) : (
                <div className="embed-responsive embed-responsive-21by9 m-t-20">
                  <iframe
                    src={d.data().url}
                    allowFullScreen
                    title="myFrame"
                  ></iframe>
                </div>
              )}
            </VerticalTimelineElement>
          );
        });

        setItems((i) => [...i, ...list]);
        if (snap.docs.length < size) {
          setHasMore(false);
        }
        setLast(snap.docs[snap.docs.length - 1]);
      });
  };

  return (
    <Fragment>
      <VerticalTimeline layout={"1-column"}>
        {!isLoading ? (
          <InfiniteScroll
            dataLength={3} //This is important field to render the next data
            next={paginate}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {items}
          </InfiniteScroll>
        ) : (
          <Loader />
        )}
      </VerticalTimeline>
    </Fragment>
  );
};
export default VerticalTimelineComp;
