import React, { useState } from "react";
import {
  Button,
  Carousel,
  CarouselItem,
  Input,
  Label,
  Media,
  Modal,
  ModalFooter,
} from "reactstrap";
import { firebase_app } from "../../data/config";

const WelcomeTour = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    const uid = localStorage.getItem("uid");

    firebase_app
      .firestore()
      .collection("donors")
      .doc(uid)
      .update({ isNewUser: !accept, accept: accept });

    return setIsOpen(false);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const [accept, setAccept] = useState(false);

  const items = [
    {
      type: "html",
      html: ``,
    },
    {
      type: "html",
      html: ``,
    },
    {
      type: "html",
      html: ``,
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  return (
    <Modal isOpen={isOpen} centered size="lg">
      <div className="modal-body p-0">
        <Carousel
          interval={false}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          {items.map((item, i) =>
            item.type === "media" ? (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={i}
              >
                <Media
                  src={item.src}
                  alt={item.altText}
                  className="img-fluid"
                />
              </CarouselItem>
            ) : i === 0 ? (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={i}
              >
                <div
                  style={{
                    minHeight: 495,
                    display: "flex",
                    flexDirection: "column",
                    padding: 50,
                    justifyContent: "center",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>
                    Child Protection Policy
                  </h4>
                  <p className="f-16">
                    In this page, you will find information on the development
                    and day to day updates of the child you are helping through
                    your contribution. Most of our children come from vulnerable
                    backgrounds and their identity and privacy thus needs to be
                    protected.
                  </p>
                </div>
              </CarouselItem>
            ) : i === 1 ? (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={i}
              >
                <div
                  style={{
                    minHeight: 495,
                    display: "flex",
                    flexDirection: "column",
                    padding: 50,
                    justifyContent: "center",
                  }}
                  className="values-content"
                >
                  <h4 style={{ textAlign: "center" }}>
                    Child Protection Policy
                  </h4>
                  <p className="f-16">
                    Under our Child Protection Policy and the United Nations
                    Convention on Child Rights, we have to withhold certain
                    information of the child that may:
                    <ol>
                      <li>
                        Disclose/help identify the child and help locate him/her
                        to his/her place of residence.
                      </li>
                      <li>
                        Disclose personal information that violates his/her
                        rights to privacy.
                      </li>
                      <li>
                        Render misuse of the information against the child,
                        his/her place of residence and/or his/her
                        parents/guardians.
                      </li>
                    </ol>
                  </p>
                </div>
              </CarouselItem>
            ) : i === 2 ? (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={i}
              >
                <div
                  style={{
                    minHeight: 495,
                    display: "flex",
                    flexDirection: "column",
                    padding: 50,
                    justifyContent: "center",
                  }}
                  className="values-content"
                >
                  <h4 style={{ textAlign: "center" }}>
                    Child Protection Policy
                  </h4>
                  <p className="f-16">
                    By agreeing to this policy, you are accepting the following:
                    <ol>
                      <li>
                        I will use this portal and all the information shared
                        with me for my information only.
                      </li>
                      <li>
                        I understand that the information disclosed on this
                        portal is for me as a donor to track the progress of my
                        child and not for public view.
                      </li>
                      <li>
                        I understand that I cannot use the information provided
                        on this portal for any other use other than for my own
                        informational purpose.
                      </li>
                      <li>
                        I confirm that I will not share information disclosed on
                        this portal with anyone else either directly or through
                        any other means including but not limited to social
                        media.
                      </li>
                      <li>
                        I agree to the child’s right to privacy and I intend to
                        uphold it within my means.
                      </li>
                    </ol>
                  </p>
                  <div className="checkbox checkbox-solid-primary">
                    <Input
                      id="checkbox-primary-1"
                      type="checkbox"
                      className="checkbox_animated"
                      value={accept}
                      onChange={() => setAccept(!accept)}
                    />
                    <Label for="checkbox-primary-1" className="f-16">
                      {"I Accept"}
                    </Label>
                  </div>
                </div>
              </CarouselItem>
            ) : null
          )}
        </Carousel>
      </div>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={previous}
          disabled={activeIndex === 0}
        >
          {"Previous"}
        </Button>
        <Button
          color="primary"
          onClick={activeIndex < items.length - 1 ? next : closeModal}
          disabled={activeIndex < items.length - 1 ? false : !accept}
        >
          {activeIndex < items.length - 1 ? "Next" : "Finish"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default WelcomeTour;
