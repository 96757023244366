import React, { Fragment, useState, useEffect, useContext } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { db } from "../../data/config";

import configDB from "../../data/customizer/config";
import SelectedContext from "../../context/selected-context";
import Loader from "../ui-kits/loader";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const sampleSeries = {
  respect: {
    yaxis: [2, 2, 3, 3, 2, 2],
    xaxis: [1, 2, 3, 4, 5, 6],
  },
};

export const apexAreaChart = {
  series: [
    {
      name: "respect",
      data: sampleSeries.respect.yaxis,
    },
  ],
  options: {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    colors: [primary],
    labels: sampleSeries.respect.axis,
    xaxis: {
      type: "number",
      min: 0,
    },
    yaxis: {
      min: 0,
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};

const MentalHealth = (props) => {
  const [students, setStudents] = useState(null);
  const { selectedStudent } = useContext(SelectedContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const docRef = db.collection("students").doc(selectedStudent);

    db.collection("assessment")
      .where("studentUID", "==", docRef)
      .where("assessmentName", "==", "Mental Health Assessment")
      .get()
      .then((a) => {
        if (a.docs.length > 0) {
          a.docs.forEach((e) => {
            setIsLoading(false);
            setStudents((i) => ({
              name: "name",
              url: e.data().assessmentReports["Mental Health Report"],
            }));
          });
        } else {
          setStudents("empty");
          setIsLoading(false);
        }
      });
  }, [selectedStudent]);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Mental Well-Being" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update values-content">
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  <span className="f-20">Mental Well-Being</span> of a child in
                  KEYtaab is assessed through two mediums:
                </p>
                <ol className="f-16">
                  <li>
                    General Mental Well-being Test: This test is first conducted
                    after 4 months of starting the project. This gives the child
                    enough time to trust the people working with him/her and
                    open the prospect of sharing his thoughts and emotions. The
                    test aims to assess the current mental state of the child
                    and gauge the positive and negative scores on the well-being
                    scale
                  </li>
                  <li>
                    Tutor/Facilitator reference:
                    <br />
                    Our resource persons who work with the children are trained
                    in identifying behaviour indicators that helps highlight
                    concerning behaviours which are then relayed to our
                    counseling panel for further assessment and remedy.
                  </li>
                </ol>
                <br />
                <p className="f-16">
                  Our reports comprise the above two mediums which gives us a
                  near accurate understanding of the child’s mental well-being
                  and take relevant measures for its upkeep.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5>Mental Health Report</h5>
              </CardHeader>
              <CardBody>
                {students !== null && !isLoading ? (
                  students === "empty" ? (
                    <p className="f-16">
                      Mental Well Being Assessment will be conducted after 3
                      months of project commencement
                    </p>
                  ) : (
                    <object
                      style={{ width: "100%", height: "80vh" }}
                      data={students.url}
                      type="application/pdf"
                    >
                      <iframe
                        title="Mental Well Being Report"
                        src="https://docs.google.com/viewer?url=your_url_to_pdf&embedded=true"
                      ></iframe>
                    </object>
                  )
                ) : (
                  <Loader />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MentalHealth;
