import React, { Fragment, useState, useEffect, useContext } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { firebase_app } from "../../data/config";

import Chart from "react-apexcharts";
import configDB from "../../data/customizer/config";
import SelectedContext from "../../context/selected-context";
import Loader from "../ui-kits/loader";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const areaSpaline = {
  options: {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    colors: [primary, "#f10542"],
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "number",
    },
  },
};

const AcademicReport = (props) => {
  const [schoolAssessment, setSchoolAssessment] = useState(null);
  const [internalAssessment, setInternalAssessment] = useState(null);

  const calculatePercentage = (obj, key) => {
    if (obj[key].obtainedMarks === "" || obj[key].totalMarks === "") {
      return 0;
    }
    // console.log(obj[key].obtainedMarks, obj[key].totalMarks);
    return (Number(obj[key].obtainedMarks) * 100) / Number(obj[key].totalMarks);
  };

  const { selectedStudent } = useContext(SelectedContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let ia = {};
    let sa = {};
    setIsLoading(true);

    const docRef = firebase_app
      .firestore()
      .collection("students")
      .doc(selectedStudent);

    firebase_app
      .firestore()
      .collection("assessment")
      .where("studentUID", "==", docRef)
      .where("assessmentName", "in", [
        "Internal Assessment",
        "School Assessment",
      ])
      .get()
      .then((a) => {
        if (a.docs.length > 0) {
          a.docs.forEach((e) => {
            if (e.data().assessmentName === "Internal Assessment") {
              const k = e.data().assessmentValues;
              Object.keys(k).forEach((key) => {
                if (!ia[key]) {
                  ia[key] = [];
                }
                ia[key].push(calculatePercentage(k, key));
              });
            }
            if (e.data().assessmentName === "School Assessment") {
              const k = e.data().assessmentValues;

              Object.keys(k).forEach((key) => {
                if (!sa[key]) {
                  sa[key] = [];
                }
                sa[key].push(calculatePercentage(k, key));
              });
            }
          });
          const oia = Object.keys(ia)
            .sort()
            .reduce((obj, key) => {
              obj[key] = ia[key];
              return obj;
            }, {});

          setIsLoading(false);
          setInternalAssessment(oia);
          setSchoolAssessment(sa);
        } else {
          setInternalAssessment("empty");
          setSchoolAssessment("empty");
          setIsLoading(false);
        }
      });
  }, [selectedStudent]);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Academic Report" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          {schoolAssessment !== null &&
          internalAssessment !== null &&
          !isLoading ? (
            schoolAssessment === "empty" && internalAssessment === "empty" ? (
              <Col xl="12">
                <Card>
                  <CardBody>
                    <p className="f-16">
                      Awaiting school results. <br /> It will be uploaded
                      shortly
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              Object.keys(internalAssessment).map((subject, i) => (
                <Col xl="12" key={i}>
                  <Card>
                    <CardHeader>{subject}</CardHeader>
                    <CardBody>
                      <Chart
                        options={{
                          ...areaSpaline.options,
                          xaxis: {
                            type: "number",
                            categories: internalAssessment[subject].map(
                              (_a, index) => index
                            ),
                          },
                        }}
                        series={[
                          {
                            name: "internal assessment",
                            data: internalAssessment[subject],
                          },
                          {
                            name: "school assessment",
                            data: schoolAssessment[subject],
                          },
                        ]}
                        height="350"
                        type="area"
                      />
                    </CardBody>
                  </Card>
                </Col>
              ))
            )
          ) : (
            <Col xl="12">
              <Card>
                <CardBody>
                  <Loader />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AcademicReport;
