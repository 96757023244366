import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { FirebaseAnalytics, firebase_app } from "./data/config";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import App from "./components/app";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";
import ConfigDB from "./data/customizer/config";
import {
  configureFakeBackend,
  authHeader,
  handleResponse,
} from "./services/fack.backend";

// Signin page
import Signin from "./auth/signin";

// Authentication
import Login from "./pages/authentication/login";
import LoginWithBgImage from "./pages/authentication/loginWithBgImage";
import LoginWithBgVideo from "./pages/authentication/loginWithBgVideo";
import LoginWithValidation from "./pages/authentication/loginwithValidation";
import Register from "./pages/authentication/register";
import RegisterWithBgImage from "./pages/authentication/registerWithBgImage";
import RegisterWithBgVideo from "./pages/authentication/registerWithBgVideo";
import UnlockUser from "./pages/authentication/unlockUser";
import Forgetpwd from "./pages/authentication/forgetpwd";
import Resetpwd from "./pages/authentication/resetpwd";

// Error page
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Maintenanc
import Maintenance from "./pages/maintenance";
import SelectedContext from "./context/selected-context";

import "firebase/performance";
import "firebase/messaging";

// setup fake backend
configureFakeBackend();

const Root = (props) => {
  const [anim, setAnim] = useState("");
  firebase_app.performance();
  // eslint-disable-next-line no-unused-vars
  const [showNotificationRequest, setShowNotificationRequest] = useState(false);

  try {
    const messaging = firebase_app.messaging();

    messaging
      .getToken({
        vapidKey:
          "BOqM0aVVDdP_b_2kwTjxKDAFDmYKo3RFPEge3UN0wWqgf_EbbPNzWF0wTazASEoHfcn3FTKa-6TB5A8yMHGA6eQ",
      })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log(currentToken);
        } else {
          // Show permission request UI
          setShowNotificationRequest(true);
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        if (err.code === "messaging/permission-blocked") {
          // setShowNotificationRequest(true);
          console.log("Please Unblock Notification Request Manually");
        } else {
          console.log("Error Occurred", err);
        }
      });
  } catch (error) {
    console.log(error);
  }

  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();
  const [currentUser, setCurrentUser] = useState(null);
  // const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    const requestOptions = { method: "GET", headers: authHeader() };
    fetch("/users", requestOptions).then(handleResponse);
    setAnim(animation);
    firebase_app.auth().onAuthStateChanged((user) => {
      localStorage.setItem("uid", user.uid);
      firebase_app
        .firestore()
        .collection("donors")
        .doc(user.uid)
        .get()
        .then((donor) => {
          const shelterhomes = donor
            .data()
            .shelterhomeUID?.map((i) => i.id)
            .toString();

          const students = donor
            .data()
            .studentUID?.map((i) => i.id)
            .toString();

          localStorage.setItem("student", students || "1sWQ9MIHz233bUZebTUj");
          localStorage.setItem(
            "selectStudent",
            donor.data().studentUID?.map((i) => i.id)[0] ||
              "1sWQ9MIHz233bUZebTUj"
          );
          setSelectedStudent(donor.data().studentUID?.map((i) => i.id)[0]);

          localStorage.setItem(
            "shelterhome",
            shelterhomes || "Don0WLCfqQddwo0HaUaK"
          );
          localStorage.setItem(
            "selectShelterhome",
            donor.data().shelterhomeUID?.map((i) => i.id)[0] ||
              "Don0WLCfqQddwo0HaUaK"
          );
          setSelectedShelterhome(
            donor.data().shelterhomeUID?.map((i) => i.id)[0]
          );
        });
      setCurrentUser(user);
    });

    // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  const [selectedStudent, setSelectedStudent] = useState(
    localStorage.getItem("selectStudent")
  );
  const [selectedShelterhome, setSelectedShelterhome] = useState(
    localStorage.getItem("selectShelterhome")
  );

  // eslint-disable-next-line no-unused-vars
  const rejectShowNotifications = () => {
    setShowNotificationRequest(false);
    const date = new Date();
    date.setTime(date.getTime() + 3 * 24 * 60 * 60 * 1000);
    const expires = "; expires=" + date.toUTCString();
    document.cookie = `notificationUI=hide${expires}; path=/`;
  };

  // eslint-disable-next-line no-unused-vars
  // const successShowNotifications = () => {
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       console.log("Have Permission");
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       console.log("FCM Token:", token);
  //       setShowNotificationRequest(false);

  //       //you probably want to send your new found FCM token to the
  //       //application server so that they can send any push
  //       //notification to you.
  //     })
  //     .catch((error) => {
  //       setShowNotificationRequest(false);
  //       const date = new Date();
  //       date.setTime(date.getTime() + 3 * 24 * 60 * 60 * 1000);
  //       const expires = "; expires=" + date.toUTCString();
  //       document.cookie = `notificationUI=hide${expires}; path=/`;
  //       if (error.code === "messaging/permission-blocked") {
  //         console.log("Please Unblock Notification Request Manually");
  //       } else {
  //         console.log("Error Occurred", error);
  //       }
  //     });
  // };

  return (
    <Fragment>
      <Provider store={store}>
        <SelectedContext.Provider
          value={{
            selectedStudent,
            setSelectedStudent,
            selectedShelterhome,
            setSelectedShelterhome,
          }}
        >
          {/* {showNotificationRequest ? (
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                left: "20px",
                zIndex: "99",
              }}
            >
              <Card className="shadow shadow-showcase p-20">
                <h3>Don't miss a moment</h3>
                <p className="f-16">
                  Receive Notifications when there is something special for you
                  to view
                </p>
                <div style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    style={{ margin: "0 20px" }}
                    onClick={rejectShowNotifications}
                  >
                    No, thanks
                  </Button>
                  <Button
                    color="primary"
                    style={{ margin: "0 20px" }}
                    onClick={successShowNotifications}
                  >
                    Get notifications
                  </Button>
                </div>
              </Card>
            </div>
          ) : null} */}
          <BrowserRouter basename={`/`}>
            <FirebaseAnalytics />
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/login`}
                component={Login}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg1`}
                component={LoginWithBgImage}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg2`}
                component={LoginWithBgVideo}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/loginWithValidation`}
                component={LoginWithValidation}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signup`}
                component={Register}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg1`}
                component={RegisterWithBgImage}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg2`}
                component={RegisterWithBgVideo}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/forgetPwd`}
                component={Forgetpwd}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`}
                component={UnlockUser}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/auth/resetPwd`}
                component={Resetpwd}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error400`}
                component={Error400}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error401`}
                component={Error401}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error403`}
                component={Error403}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error404`}
                component={Error404}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error500`}
                component={Error500}
              ></Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/errors/error503`}
                component={Error503}
              ></Route>

              <Route
                path={`${process.env.PUBLIC_URL}/pages/maintenance`}
                component={Maintenance}
              ></Route>

              {currentUser !== null || jwt_token ? (
                <App>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    render={() => {
                      return (
                        <Redirect
                          to={`${process.env.PUBLIC_URL}/dashboard/news-feed`}
                        />
                      );
                    }}
                  />
                  <TransitionGroup>
                    {routes.map(({ path, Component }) => (
                      <Route
                        key={path}
                        exact
                        path={`${process.env.PUBLIC_URL}${path}`}
                      >
                        {({ match }) => (
                          <CSSTransition
                            in={match != null}
                            timeout={100}
                            classNames={anim}
                            unmountOnExit
                          >
                            <div>
                              <Component />
                            </div>
                          </CSSTransition>
                        )}
                      </Route>
                    ))}
                  </TransitionGroup>
                </App>
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
            </Switch>
          </BrowserRouter>
        </SelectedContext.Provider>
      </Provider>
    </Fragment>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
