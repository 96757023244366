import React, { Fragment, useState, useEffect, useContext } from "react";
import { firebase_app } from "../../data/config";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, CardHeader, CardBody, Card } from "reactstrap";

import Chart from "react-apexcharts";
import SelectedContext from "../../context/selected-context";
import Loader from "../ui-kits/loader.jsx";

const keyMap = { Absent: 1, Minimal: 2, Good: 3, Excellent: 4 };

export const apexColumnChart = {
  series: [],
  options: {
    chart: {
      type: "bar",
      stacked: true,
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
  },
};

const Values = (props) => {
  const groupKey = (arr, key, name = "") => {
    const xaxis = [];
    const yaxis = [];
    arr.forEach((e, index) => {
      xaxis.push(index);
      yaxis.push(keyMap[e.data()[key]]);
    });
    return { name, key, xaxis, yaxis };
  };

  const [students, setStudents] = useState(null);
  const { selectedStudent } = useContext(SelectedContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const docRef = firebase_app
      .firestore()
      .collection("students")
      .doc(selectedStudent);

    firebase_app
      .firestore()
      .collection("feedbacks")
      .where("studentUID", "==", docRef)
      .get()
      .then((a) => {
        if (a.docs.length > 0) {
          setIsLoading(false);
          setStudents({
            respect: groupKey(a.docs, "respect"),
            accountabilty: groupKey(a.docs, "accountability"),
            diligence: groupKey(a.docs, "diligence"),
            personalHygiene: groupKey(a.docs, "personalHygiene"),
            initiative: groupKey(a.docs, "initiative"),
          });
        } else {
          setStudents("empty");
          setIsLoading(false);
        }
      });
  }, [selectedStudent]);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Values" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update values-content">
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  <span className="f-20">Values</span> is a part of Project
                  KEYtaab’s Social-Emotional Learning aspect. Our children who
                  live in shelter homes, often stay there because they don’t
                  have parents or have parents who are not able to take care of
                  them. Similarly, our children in communities see their parents
                  less as they work long hours to make ends meet.
                </p>
                <p className="f-16">
                  In both cases, there’s absentee parenting. Which means, the
                  values that parents and family structures inculcate in
                  children from a young age which shapes his/her personality,
                  ideals and world-view, are not being inculcated for these
                  children. As a result, they grow up without core values, often
                  find it difficult to distinguish between right and wrong and
                  struggle with their morals which may lead them to making wrong
                  decisions in life.
                </p>
                <p className="f-16">
                  Through our program, we inculcate 5 core values which are
                  essential for a child to develop from a young age. This when
                  inculcated, creates the base of a strong foundation of morals
                  and makes it easier to inculcate more such values as they grow
                  up.
                </p>
                <p className="f-16">Our 5 core values are:</p>
                <ol className="f-16">
                  <li>
                    <span>R</span>especting self and others
                  </li>
                  <li>
                    <span>A</span>ccountability
                  </li>
                  <li>
                    <span>P</span>ersonal Hygiene
                  </li>
                  <li>
                    <span>I</span>nitiative
                  </li>
                  <li>
                    <span>D</span>iligence
                  </li>
                </ol>
                <br />
                <p className="f-16">
                  Values take time to develop and the most efficient way of
                  inculcating it is weaving it into the fabric of the day to day
                  lives of the children. Our programs are designed to ensure
                  that these values are reiterated regularly overtime to
                  develop.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h5>Progress Graph</h5>
              </CardHeader>
              <CardBody>
                {students !== null && !isLoading ? (
                  students === "empty" ? (
                    <p className="f-16">
                      The scores will start reflecting on the page, once we
                      start our intervention with the child
                    </p>
                  ) : (
                    <Chart
                      height={500}
                      type="bar"
                      options={{
                        ...apexColumnChart.options,
                        xaxis: {
                          categories: students
                            ? Object.keys(students).length
                              ? students[Object.keys(students)[0]].xaxis
                              : []
                            : [],
                        },
                      }}
                      series={Object.keys(students).map((key) => {
                        return { name: key, data: students[key].yaxis };
                      })}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  Each of our values is colour coded to show the progress over
                  the past 6 weeks. The x-axis shows the number of weeks, while
                  the Y-axis shows the value scores. Each number box represents
                  the score of the value where 1 is Absent, 2 is Minimal, 3 is
                  Good and 4 is Excellent.
                </p>
                <p className="f-16">
                  <span>How are we gauging the development of values?</span>
                </p>
                <ul className="f-16">
                  <li>
                    Each child is marked on these values in every session we
                    conduct.
                  </li>
                  <li>
                    They are marked on a rubric on Absent - Minimal - Good -
                    Excellent
                  </li>
                  <li>
                    At the end of each month, we average the score to see which
                    values are scoring less.
                  </li>
                  <li>
                    This lets our resource person on the ground know exactly
                    which value to focus on for a particular child in the next
                    month.
                  </li>
                  <li>
                    The aim is to ensure over time, each child is able to
                    maintain a score between good and excellent.
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Values;
