import React, { useState } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import { firebase_app } from "../data/config";

const ForgotPasswordModal = ({ toggle }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onForgotPassword = () => {
    setLoading(true);
    try {
      firebase_app
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          setTimeout(() => {
            toast.success("Password reset link has been sent to your Email id");
          }, 200);
          toggle();
          setLoading(false);
        })
        .catch(function (error) {
          // An error happened.
          setTimeout(() => {
            toast.error(error.message);
          }, 200);
          setLoading(false);
        });
    } catch (e) {
      setTimeout(() => {
        toast.error(e.message);
      }, 200);
    }
  };
  return (
    <Modal isOpen={true} toggle={toggle} centered>
      <ModalHeader>Update your password</ModalHeader>
      <ModalBody>
        <Form className="theme-form">
          <FormGroup>
            <Label className="col-form-label">Enter you Email Id</Label>
            <Input
              className="form-control"
              type="text"
              required=""
              placeholder="Enter Email Id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onForgotPassword} disabled={loading}>
          {loading ? "LOADING..." : "Reset Password"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ForgotPasswordModal;
