import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import VerticalTimelineComp from "../bonus_ui/timelines/verticalTimelineComp";
import { useLocation } from "react-router-dom";
import WelcomeTour from "../ui-kits/welcome-tour";

const NewsFeed = (props) => {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const welcome = query.get("welcome");

  const [isOpen, setIsOpen] = useState(welcome !== null);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="News Feed" />
      <WelcomeTour isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <VerticalTimelineComp />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewsFeed;
