import React, { Fragment, useState, useEffect, useContext } from "react";
import { firebase_app } from "../../data/config";

import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import Chart from "react-apexcharts";
import configDB from "../../data/customizer/config";
import SelectedContext from "../../context/selected-context";
import Loader from "../ui-kits/loader";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;

const sampleSeries = {
  respect: {
    yaxis: [2, 2, 3, 3, 2, 2],
    xaxis: [1, 2, 3, 4, 5, 6],
  },
};

export const apexAreaChart = {
  series: [
    {
      name: "respect",
      data: sampleSeries.respect.yaxis,
    },
  ],
  options: {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    colors: [primary],
    labels: sampleSeries.respect.axis,
    xaxis: {
      type: "number",
      min: 0,
    },
    yaxis: {
      min: 0,
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};

const LifeSkills = (props) => {
  const keyMap = { Absent: 1, Minimal: 2, Good: 3, Excellent: 4 };

  const apexBarChart = {
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {},
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#ea4633"],
      yaxis: {
        min: 0,
        max: 4,
        tickAmount: 4,
        labels: {
          align: "left",
          formatter: (value) =>
            value === 1
              ? "Absent"
              : value === 2
              ? "Minimal"
              : value === 3
              ? "Good"
              : value === 4
              ? "Excellent"
              : "",
        },
      },
    },
  };
  // const findKey = (num) => {
  //   num = Math.floor(num);
  //   let key = "";
  //   Object.keys(keyMap).forEach((k) => {
  //     if (keyMap[k] === num) {
  //       key = k;
  //       return;
  //     }
  //   });
  //   return key;
  // };

  const averageOut = (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      const {
        comment,
        obtainedMarks,
        totalMarks,
        indicator2,
        indicator3,
        indicator4,
        ...other
      } = obj[key];

      let values = Object.values(other);
      newObj[key] =
        values.reduce((ac, cv) => {
          return ac + keyMap[cv];
        }, 0) / values.length;
    });
    return newObj;
  };

  const [students, setStudents] = useState(null);
  const { selectedStudent } = useContext(SelectedContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const docRef = firebase_app
      .firestore()
      .collection("students")
      .doc(selectedStudent);

    firebase_app
      .firestore()
      .collection("assessment")
      .where("studentUID", "==", docRef)
      .where("assessmentName", "==", "Life Skills - Baseline ")
      .get()
      .then((a) => {
        if (a.docs.length > 0) {
          a.docs.forEach((e) => {
            const values = e.data().assessmentValues;
            const k = {
              name: "a",
              id: e.id,
              value: averageOut(values),
              currentMonth: e.data().assessmentQuestions[
                "Current Assessment Month?"
              ],
              nextMonth: e.data().assessmentQuestions["Next Assessment Month?"],
            };
            setIsLoading(false);
            setStudents(k);
          });
        } else {
          setStudents("empty");
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudent]);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Life Skill Development" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  <span className="f-20">Life skills</span> is a part of Project
                  KEYtaab’s Social-Emotional Learning aspect. 21st century life
                  skills such as decision making, critical thinking,
                  interpersonal skills, are crucial aspects of a child’s
                  development. This when inculcated from childhood, enhances the
                  child’s capacity to survive and thrive in the world, make
                  informed decisions and choices that benefits his/her life and
                  at the same time be cognizant of his/her impact on the lives
                  of others.
                </p>
                <p className="f-16">
                  Through this program, we focus on 9 core life skills that form
                  the foundation and help the child know himself/herself better
                  and develop his/her interaction with the world around them.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardHeader>
                <h5>Student Abilities - Bar Chart</h5>
              </CardHeader>
              <CardBody>
                {students !== null && !isLoading ? (
                  students === "empty" ? (
                    <p className="f-16">
                      Life Skills has not been assessed for this child, as
                      he/she is young and do not yet have comprehension for it.
                    </p>
                  ) : (
                    <Chart
                      options={{
                        ...apexBarChart.options,
                        xaxis: { categories: Object.keys(students.value) },
                      }}
                      series={apexBarChart.series.map((s) => ({
                        data: Object.values(students.value),
                      }))}
                      height="350"
                      type="bar"
                    />
                  )
                ) : (
                  <Loader />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  Each bar represents a life skill on which the child is
                  assessed. The X-axis shows the life skills while the Y-axis
                  shows the scores of each skill. Each skill is evaluated on a
                  scale of 1 to 4, where 1 is Absent, 2 is Minimal, 3 is Good, 4
                  is Excellent.
                </p>
                <p className="f-16">
                  These life skills are assessed first as baseline assessment
                  before starting the project. Subsequently, they are reassessed
                  every 4 months as consequent midlines. The above assessment
                  was conducted in "{students?.currentMonth}" and the next
                  midline will be held on "{students?.nextMonth}".
                </p>
                <p className="f-16">
                  The rationale for the 4 month gap is that these life skills
                  take time to develop in the child and these regular intervals
                  lets us measure a significant change.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LifeSkills;
