import React, { useState } from "react";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import { firebase_app } from "../data/config";

const ConfirmPassword = ({ toggle }) => {
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onResetPassword = () => {
    setLoading(true);

    const user = firebase_app.auth().currentUser;
    try {
      if (password.length < 6)
        throw new Error("Please Enter Password of minimum 6 characters.");
      if (!password === confPassword)
        throw new Error("Oppss.. The password doesn't match.");

      user
        .updatePassword(password)
        .then(
          () =>
            (window.location.href = `${process.env.PUBLIC_URL}/dashboard/news-feed?welcome=user`)
        );
      // } else {
      //   setTimeout(() => {
      //     toast.error("Oppss.. The password doesn't match");
      //   }, 200);
      // }
    } catch (e) {
      setTimeout(() => {
        toast.error(e.message);
      }, 200);
    }
  };
  return (
    <Modal isOpen={true} centered>
      <ModalHeader>Update your password</ModalHeader>
      <ModalBody>
        <Form className="theme-form">
          <FormGroup>
            <Label className="col-form-label">New Password</Label>
            <Input
              className="form-control"
              type="password"
              required=""
              placeholder="Enter New Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label">Confirm Password</Label>
            <Input
              className="form-control"
              type="password"
              required=""
              placeholder="Confirm New Password"
              onChange={(e) => setConfPassword(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onResetPassword} disabled={loading}>
          {loading ? "LOADING..." : "Reset Password"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmPassword;
