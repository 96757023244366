import React, { Fragment, useState, useEffect, useContext } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Minimize } from "react-feather";
import { useHistory } from "react-router-dom";
import { analytics, firebase_app } from "../../data/config";
import { translate } from "react-switch-lang";

import { LogOut } from "../../constant";
import SelectedContext from "../../context/selected-context";

const Rightbar = (props) => {
  const history = useHistory();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [moonlight, setMoonlight] = useState(false);

  const [studentDropdown, setStudentDropdown] = useState(false);
  const [student, setStudent] = useState(null);
  const [students, setStudents] = useState(null);

  const [shelterhomeDropdown, setShelterhomeDropdown] = useState(false);
  const [shelterhome, setShelterhome] = useState(null);
  const [shelterhomes, setShelterhomes] = useState(null);
  const { setSelectedStudent, setSelectedShelterhome } = useContext(
    SelectedContext
  );

  useEffect(() => {
    const getShelterhomes = async () => {
      const listOfShelterhomes = localStorage
        .getItem("shelterhome")
        ?.split(",") || ["Don0WLCfqQddwo0HaUaK"];

      const dataOfShelterhomes = await Promise.all(
        listOfShelterhomes.map((shelterhome) =>
          firebase_app
            .firestore()
            .collection(`shelterHome`)
            .doc(shelterhome)
            .get()
            .then((doc) => ({ id: doc.id, ...doc.data() }))
        )
      );

      setShelterhome(
        dataOfShelterhomes.filter(
          (i) => i.id === localStorage.getItem("selectShelterhome")
        )[0]
      );
      setShelterhomes(dataOfShelterhomes);
    };

    const getStudents = async () => {
      const listOfStudents = localStorage.getItem("student")?.split(",") || [
        "1sWQ9MIHz233bUZebTUj",
      ];

      const dataOfStudents = await Promise.all(
        listOfStudents.map((student) =>
          firebase_app
            .firestore()
            .collection(`students`)
            .doc(student)
            .get()
            .then((doc) => ({ id: doc.id, ...doc.data() }))
        )
      );

      setStudent(
        dataOfStudents.filter(
          (i) => i.id === localStorage.getItem("selectStudent")
        )[0]
      );
      setStudents(dataOfStudents);
    };

    getShelterhomes();

    getStudents();

    setProfile(localStorage.getItem("profileURL") || man);

    setName(localStorage.getItem("Name"));
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  const Logout_From_Firebase = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("Name");
    localStorage.removeItem("donorData");
    localStorage.removeItem("shelterhome");
    localStorage.removeItem("uid");
    localStorage.removeItem("selectStudent");
    localStorage.removeItem("student");
    localStorage.removeItem("selectShelterhome");
    localStorage.removeItem("Name");

    firebase_app.auth().signOut();
    analytics.logEvent("logout");

    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  const handleStudentDropdown = (id) => {
    localStorage.setItem("selectStudent", id);
    setStudent(students.filter((i) => i.id === id)[0]);
    setSelectedStudent(id);
    setStudentDropdown(false);
  };

  const handleShelterhomeDropdown = (id) => {
    localStorage.setItem("selectShelterhome", id);
    setShelterhome(shelterhomes.filter((i) => i.id === id)[0]);
    setSelectedShelterhome(id);
    setShelterhomeDropdown(false);
  };

  return (
    <Fragment>
      <div className="nav-right col-10 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="language-nav">
            <div
              className={`translate_wrapper ${
                shelterhomeDropdown ? "active" : ""
              }`}
            >
              <div className="current_lang">
                <div
                  className="lang"
                  onClick={() => setShelterhomeDropdown(!shelterhomeDropdown)}
                >
                  <span className="lang-txt">
                    {shelterhome ? shelterhome.name : "...Loading"}
                    <i
                      className="middle fa fa-angle-down"
                      style={{
                        position: "relative",
                        paddingLeft: "10px",
                        top: "2px",
                      }}
                    ></i>
                  </span>
                </div>
              </div>
              <div
                className={`more_lang ${shelterhomeDropdown ? "active" : ""}`}
              >
                {shelterhomes &&
                  shelterhomes.map((shelterhome, i) => (
                    <div
                      key={i}
                      className="lang"
                      onClick={() => handleShelterhomeDropdown(shelterhome.id)}
                    >
                      <span className="lang-txt">{shelterhome.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </li>

          <li className="language-nav">
            <div
              className={`translate_wrapper ${studentDropdown ? "active" : ""}`}
            >
              <div className="current_lang">
                <div
                  className="lang"
                  onClick={() => setStudentDropdown(!studentDropdown)}
                >
                  <span className="lang-txt">
                    {student ? student.name : "...Loading"}
                    <i
                      className="middle fa fa-angle-down"
                      style={{
                        position: "relative",
                        paddingLeft: "10px",
                        top: "2px",
                      }}
                    ></i>
                  </span>
                </div>
              </div>
              <div className={`more_lang ${studentDropdown ? "active" : ""}`}>
                {students &&
                  students.map((student, i) => (
                    <div
                      key={i}
                      className="lang"
                      onClick={() => handleStudentDropdown(student.id)}
                    >
                      <span className="lang-txt">{student.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </li>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0 ">
            <div className="media profile-media">
              <img className="b-r-10" src={profile} alt="" />
              <div className="media-body">
                <span>{name}</span>
                <p className="mb-0 font-roboto">
                  {"Donor"} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div active">
              <li onClick={Logout_From_Firebase}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
