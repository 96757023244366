import React, { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, CardBody, Card } from "reactstrap";

const AboutProjectKeytaab = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="About Project KEYtaab" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update values-content">
          <Col xl="12">
            <Card>
              <CardBody>
                <p className="f-16">
                  This program aims to cater to the educational and
                  developmental needs of the children of shelter homes and
                  underserved communities in India in the age group of 5 to 18
                  years It covers three key aspects of child development:
                </p>
                <ol className="f-16">
                  <li>
                    <strong>Social-Emotional Learning</strong> <br />
                    SEL from the initial growing age gives a child a better
                    understanding of themselves, helps form positive
                    interpersonal relationships and shapes the way a child grows
                    up to view the world around them. <br />
                    <strong>Our SEL model covers our two pillars:</strong>
                    <ul>
                      <li>
                        Upbringing (Values):
                        <br />
                        Our own values were inculcated into us through our
                        parents, family and our immediate surroundings. This
                        shaped our personality, perspective and created the
                        foundation of our fundamental sense of right and wrong.
                        This aspect of project KEYtaab works on introducing
                        formative values and its positive reinforcement to
                        enable the child to inculcate them.
                      </li>
                      <li>
                        Lifeskills:
                        <br />
                        We have identified 9 core life skills essential for an
                        individual in the 21st century. These skills such as
                        critical thinking, decision making, resilience; helps
                        build higher order thinking in the child and guides
                        his/her actions and the ability to make informed
                        decisions.
                      </li>
                    </ul>
                  </li>
                  <br />

                  <li>
                    <strong>Academic Support</strong>
                    <br />
                    Most of our children come from a history of having gap years
                    in their schooling coupled with little to no attention on
                    basic learning, they result in poor literacy and numeracy
                    skills and academic performance.
                    <br />
                    To remedy this, our program incorporates academic support
                    for the child covering two main aspects:
                    <ol>
                      <li>
                        <strong>Conceptual Clarity</strong>
                        <br /> In this, the focus is on bridging the gap in
                        their literacy and numeracy skills and getting them on
                        the learning level equivalent to their age.
                      </li>
                      <li>
                        <strong>Improving academic performance</strong>
                        <br /> Here, the focus is on ensuring the child does
                        well in school and gets decent grades so that when
                        he/she transitions into college or his/her choice of
                        career, their academic performance doesn’t hold them
                        back.
                      </li>
                    </ol>
                    Our approach towards academic learning is experiential in
                    nature. We believe for learning to be interesting and ensure
                    its retention, a child needs to immerse himself/herself into
                    the concept using multiple senses. Our academic sessions see
                    the child create models, conduct experiments, make art and
                    present projects that lets the child learn hands-on.
                  </li>
                  <br />
                  <li>
                    <strong>Mental Well-being</strong> <br />
                    Muskurahat Foundation has worked with children in shelter
                    homes or in low income communities since the last 6 years;
                    we have observed in our interventions that the majority of
                    our children go through or are going through some form of
                    physical and mental trauma/abuse. Most of it goes largely
                    unaddressed, continuous trauma leads to toxic stress in the
                    brain that affects the developmental process. This also
                    leads to unhappy, aggressive, extremely shy, fearful and
                    distracted individuals that have reduced capacity to
                    function in society and be able to be independent and make
                    their own lives.
                  </li>
                </ol>
                <br />
                <p className="f-16">
                  Our program aims to empower the people in the immediate
                  environment of the child to identify behaviour indicators that
                  can help catch mental unrest in time and are able to create
                  positive environments for them to survive and thrive.
                </p>
                <p className="f-16">
                  We believe that they are in the best position to observe and
                  highlight concerning behaviours and when equipped with the
                  tools can do two major things that support the mental
                  well-being of the child:
                </p>
                <ol className="f-16">
                  <li>
                    Create and maintain a safe and positive environment around
                    the child that significantly contributes to the positive
                    development of the child while ensuring the child feels
                    loved and secure to express himself/herself to others.
                  </li>
                  <li>
                    Able to closely observe the child for concerning behaviours,
                    potentially know the cause/event of the change in behaviour
                    that will help in correctly assessing and creating a
                    treatment plan for the child.
                  </li>
                </ol>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AboutProjectKeytaab;
